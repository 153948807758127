import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';

import Homepage from './components/homepage/homepage';
import NavPrompt from './components/nav-prompt';
import Projects from './components/projects/projects';
import Resume from './components/resume/resume';

class App extends Component {
  constructor() {
    super();
    this.state = {
      showNav: false
    }
  }
  
  afterSeen = () => {
    this.setState({ showNav: true });
  }

  render() {
    const { showNav } = this.state;
    return (
      <div className={"content-container"}>
        <Router>
          <>
            <Route path="/" exact
              render={ props => <Homepage { ...props } afterSeen={this.afterSeen.bind(this)} />}
            />
            <Route path="/resume" component={Resume} />
						<Route path="/projects" component={Projects} />
          </>
        </Router>
        {
          showNav && <NavPrompt />
        }
      </div>
    );
  }
}

export default App;
