import React, { Component } from 'react';

import NavPrompt from './../nav-prompt';
import Prompt from './../prompt';
import ResumeOptions from './resume-options';
import ViewResume from './view-resume';

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      showResume: false,
      catResume: false
    };
  }

  afterTyped = () => {
    this.setState({ showOptions: true });
  }

  showResume = () => {
    this.setState({ showResume: true });
  }

  afterCatResume = () => {
    this.setState({ catResume: true });
  }

  content() {
    const { showResume, catResume } = this.state;

    if (showResume) {
      return (
        <>
          <Prompt text={'cat resume/resume.txt'} typeOut={true} afterTyped={this.afterCatResume.bind(this)} />
          { catResume &&
            <ViewResume />
          }
        </>
      );
    }
  }
  
  render() {
    const { showOptions } = this.state;
    return(
      <>
        <NavPrompt typeOut={false} />
        <Prompt text={'cat resume/options.txt'} typeOut={true} afterTyped={this.afterTyped.bind(this)} />
        { showOptions &&
          <ResumeOptions showResume={this.showResume.bind(this)}/>  
        }
        { this.content() }
      </>

    );
  }
}

export default Resume;
