import React, { Component } from 'react';
import './highlighted-link.css';

class HighlightedLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { link, title } = this.props;
    return (
      <a className={'highlighted-link'} href={link}>{title}</a>
    )
  }
}

export default HighlightedLink;
