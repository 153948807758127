import React, { Component } from 'react';

import HighlightedLink from './../highlighted-link';

class ViewResume extends Component {
  render() {
    return (
      <div className='resume'>
        <h3>About</h3>
        <p>Andrew is a full-stack developer, who prides himself on his ability to produce organized, efficient object-oriented code across multiple languages. Andrew is passionate about technology, specifically startups, and hopes continue to expand his skills, while continuing to be a valuable engineering asset to any technology company.</p>
        <h3>Experience</h3>
        <h3><HighlightedLink title={'2U | New York'} link={'https://2u.com'}/></h3>
        <h5>Software Engineer II (May 2017 to Present)</h5>
        <p>2U partners with world-renowned colleges and universities to provide software to power their online graduate program offerings.</p>
        <ul>
          <li>Works on the Online Campus team, which maintains the web applications students, professors, and university faculty use to administer and attend classes.</li>
          <li>Helped migrate the existing Online Campus application from the legacy PHP/ Python stack to a Rails API and an Angular frontend.</li>
          <li>Integrated AWS Cloudfront in the architecture to allow for better performance of static assets for students around the world.</li>
          <li>Currently building out the new 2U React design system, which will be used for all 2U frontend applications.</li>
        </ul>
        <h3><HighlightedLink title={'Jibe | New York'} link={'https://jibe.com'} /></h3>
        <h5>Software Engineer (June 2016 - May 2017)</h5>
        <h5>Associate Software Engineer (May 2015 - June 2016)</h5>
        <h5>Software Engineering Intern (May 2014 - May 2015)</h5>
        <p>Jibe builds SaaS recruiting solutions that integrates with existing applicant tracking software to enable companies to hire the best talent.</p>
        <ul>
          <li>Worked on Jibe Post, a Rails application that allows recruiters to post to many job boards at once directly from their applicant tracking system.</li>
          <li>Worked on the Candidate Connect app, an AngularJS application that aids recruiters in finding and reaching out to qualified job candidates.</li>
					<li>Wrote Ruby Gems crucial to the parsing and collecting of candidate data coming from application tracking systems in various formats such as CSV and Excel.</li>
				</ul>
				<h3>Education</h3>
				<h5>University of Maryland -- BS Computer Science</h5>
				<h3>Skills</h3>
				<ul>
					<li>Programming Languages: Ruby, Python, Javascript and Typescript</li>
					<li>Web Frameworks: Rails, Sinatra, AngularJS, Angular, Express, React</li>
					<li>Databases/Storage: MySql, Mongo, Postgresql, Redis</li>
					<li>Libraries: Sidekiq, Nokogiri, Mechanize, Capybara, ActiveRecord, Sequel, Sequelize, Rspec, and others.</li>
					<li>Other Relevant Skills: Git, Restful API Design, JSON/XML/SOAP API Integrations, OAuth Authentication, Nginx, AWS Cloudfront, Docker</li>
				</ul>
      </div>
    );
  }
}

export default ViewResume;
