import React, { Component } from 'react';

import './prompt.css';

class Prompt extends Component {
  
  constructor(props) {
    super(props);
    this.state = { currText: '' };
  }

  componentDidMount() {
    const { typeOut } = this.props;
    if (typeOut) {
      this.printText();
    }
  }

  printText() {
    const { text, afterTyped } = this.props;
    Promise.all(text.split('').map((ch, index) => {
      return this.appendCh(ch, this.genTypeTime(index));
    })).then(() => {
      if (afterTyped) { afterTyped() }
    });
  }

  appendCh(ch, timeout) {
    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

    return wait(timeout).then(() => {
      return this.setState((state) => {
        return { currText: state.currText + ch }
      });
    });
  }

  genTypeTime(index) {
    return ((index + 1) * 100);
  }

  text() {
    const { typeOut, text } = this.props;
    const { currText } = this.state;
    if (typeOut) {
      return currText;
    } else {
      return text;
    }
  }

  render() {
    return (
      <p className={"prompt"}>$ { this.text() }</p>
    );
  }
}

export default Prompt;
