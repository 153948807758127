import React, { Component } from 'react';

import HighlightedLink from './../highlighted-link';
import NavPrompt from './../nav-prompt';
import Prompt from './../prompt';

import './projects.css';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjects: false
    }
  }

  showProjects = () => {
    this.setState({ showProjects: true });
  }

  content() {
    return (
      <div className={'projects__container'}>
      <h3><HighlightedLink title={'Timely Reviews'} link={'https://timely-reviews.com'} /></h3>
      <p>A tool aimed at improving pull request review communication flow. Built using a Rails API serving an Angular 6 frontend application. Runs on AWS Elastic Beanstalk as a multi-container docker service with containers served from AWS ECR.</p>
			<h3><HighlightedLink title={'MLB Redzone'} link={'https://github.com/andrewparrish/mlb_redzone'} /></h3>
			<p>A chrome extension that automatically changes the chosen game for MLB.tv streaming away from commercials and toward a user's desired alternative games. Written in typescript and using React for the options frontend.</p>
      </div>
    )
  }
  
  render() {
    const { showProjects } = this.state;
    return (
      <>
        <NavPrompt typeOut={false} />
        <Prompt text={'cat projects/projects.txt'} typeOut={true} afterTyped={this.showProjects.bind(this)} />
        { showProjects &&
          this.content()
        }
      </>    
    );
  }
}

export default Projects;
