import React, { Component } from 'react';

import './welcome.css';

class Welcome extends Component {
  render() {
    return (
      <div className={'welcome__container'}>
        <h4>Welcome!</h4>
        <p>Hey there, my name is Andrew Parrish, and I'm a maker and software devoper living in New York City. If you're interested in learning more about me and some of my work, please feel free to click on one of the links below!</p>
      </div>
    )
  }
}

export default Welcome;
