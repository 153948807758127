import React, { Component } from 'react';

import Prompt from './../prompt';
import Welcome from './welcome';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = { showWelcome: false };
  }

  afterTyped = () => {
    const { afterSeen } = this.props;

    this.setState({ showWelcome: true });
    afterSeen();
  }

  render() {
    const { showWelcome } = this.state;
    return(
      <>
        <Prompt text={'cat welcome.txt'} typeOut={true} afterTyped={this.afterTyped.bind(this)} />
        { showWelcome &&
          <Welcome />
        }
      </>
    );
  }
}

export default Homepage;
