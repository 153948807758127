import React, { Component } from 'react';

import HighlightedLink from './highlighted-link';
import Prompt from './prompt';

import './nav-prompt.css';


class NavPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = { showLinks: false };
  }

  links() {
    const { showLinks } = this.state;
    if (showLinks) {
      return (
        <div className={'nav__links'}>
          <HighlightedLink link={'/'} title={'home'} />
          <HighlightedLink link={'/resume'} title={'resume'} />
          <HighlightedLink link={'/projects'} title={'projects'} />
          <HighlightedLink link={'/blog'} title={'blog'} />
        </div>
      )
    }
  }

  showLinks = () => {
    this.setState({ showLinks: true });
  }

  componentDidMount() {
    if(!this.typeOut()) {
      this.setState({ showLinks: true });
    }
  }

  typeOut() {
    const { typeOut } = this.props;
    return !(typeOut !== 'undefined' && !typeOut )
  }

  render() {
    return (
      <>
        <Prompt typeOut={this.typeOut()} text={'ls options'} />
        { this.links() }
      </>
    )
  }
}

export default NavPrompt;
