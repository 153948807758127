import React, { Component } from 'react';

import './options.css';

class ResumeOptions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showResume } = this.props;
    return (
      <div className='resume__options'>
        <h4>Choose how to view my resume:</h4>
        <ul>
          <li className={'resume__option'}><a href="https://s3.amazonaws.com/parrishresume/parrishresume.pdf">Download a pdf copy.</a></li>
          <li className={'resume__option'} onClick={showResume}>View a copy here.</li>
        </ul>
      </div>
    );
  }
}

export default ResumeOptions;
